/*
 * @Author: welson
 * @Date: 2019-02-22 11:44:36
 * @Last Modified by: welson
 * @Last Modified time: 2019-04-29 11:47:54
 */

/**
 * 点位标注 + 场所查询
 */
import React from "react";
import { Checkbox, message } from "antd";

import "./index.less";

const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const SetPointMap = Loader.loadBusinessComponent("MapComponent", "SetPointMap");
const { InfoWindow } = LMap;

@Decorator.businessProvider("place")
class ModalSetPointMap extends React.Component {
  state = {
    infoWindowVisible: false,
    center: null,
    places: [],
    selectPlaceId: null,
    polylineData: {},
    disabled: true,
    clickAble: true,
    bindId: null,
    cancelBind: false
  };
  child = null;

  componentDidMount() {
    const { point } = this.props;
    const position = [point.longitude, point.latitude];
    this.getPlaces(position);
    if (point.placeId) {
      this.getPolyline(point.placeId);
      this.setState({ bindId: point.placeId, selectPlaceId: point.placeId });
    }
  }

  /**
   * 查找点位的新场所
   *
   */
  getPlaces = async position => {
    const { point } = this.props;
    let placeIds = [];
    await Service.device.queryDeviceInfo(point.id).then(res => {
      placeIds = (res.data && res.data.placeIds) || [];
    });
    const options = {
      center: position.join(",")
    };
    BSConfig.microService.place &&
      Service.place.queryPOIByCenter(options).then(res => {
        const { places } = res.data;
        let placeInAll = places.map(p => p.placeIds[p.placeIds.length - 1]);
        let placeId = placeIds.length && placeIds[placeIds.length - 1];
        let selectPlaceId = placeInAll.find(p => p == placeId);
        this.setState(
          {
            selectPlaceId,
            places,
            placeIds
          },
          () => this.getPolyline(point.placeId)
        );
      });
  };

  handleCancel = error => {
    const { onCancel } = this.props;
    this.pointInfo = null;
    this.setState({
      infoWindowVisible: false,
      center: null,
      places: [],
      selectPlaceId: null,
      disabled: true
    });
    onCancel && onCancel(error);
  };

  handleSubmit = async () => {
    const { places, selectPlaceId, placeIds, cancelBind } = this.state;
    const { point } = this.props;

    let address = this.pointInfo ? this.pointInfo.address : point.address,
      name = this.pointInfo ? this.pointInfo.name : point.name,
      latitude = this.pointInfo ? this.pointInfo.latitude : point.latitude,
      longitude = this.pointInfo ? this.pointInfo.longitude : point.longitude,
      id = this.pointInfo ? this.pointInfo.id : point.id;
    let updateGeoOpions = {
      geoBean: {
        address,
        name,
        latitude,
        longitude
      },
      id
    };
    let func = "updateDeviceGeoAndPlace";
    if (selectPlaceId) {
      let item = places.find(v => v.placeIds[v.placeIds.length - 1] == selectPlaceId);
      if (item) {
        updateGeoOpions.geoBean.address = item.address;
        updateGeoOpions.geoBean.name = item.name;
        updateGeoOpions.placeIds = item.placeIds;
        updateGeoOpions.placeTags = item.placeTags;
      }
    } else {
      func = "updateDeviceGeo";
      updateGeoOpions = { address, name, latitude, id, longitude };
    }
    if (cancelBind) {
      // 取消场所绑定
      let option = {
        cid: point.cid,
        placeIds: placeIds
      };
      Service.place.cancelDevicePlaceRelation(option);
      updateGeoOpions.placeIds = [];
    }
    try {
      await Service.device[func](updateGeoOpions);
      const { onOk } = this.props;
      let deviceGeoOptions = {
        deviceId: id,
        latitude,
        longitude,
        type: 3
      };
      BaseStore.device.updateDevice(deviceGeoOptions);
      Shared.queryPlaceDeviceAndPerson();
      SocketEmitter.emit(SocketEmitter.eventName.changeGeoAndPlace);
      message.success("操作成功！");
      onOk && onOk(updateGeoOpions);
    } catch (e) {
      message.error("操作失败！");
      this.handleCancel(false);
    }
  };

  /**
   * @desc 点位拖拽后的回调
   */
  handleChangePoint = ({ point, position }) => {
    const { showPlaceModal } = this.props;
    this.pointInfo = point;
    if (showPlaceModal) {
      this.getPlaces(position);
      // 拖动点位显示该点位的相关弹窗
      this.setState({
        infoWindowVisible: true,
        center: position
        // clickAble:false
      });
    }
    this.setState({
      disabled: false
    });
  };
  //获取地图
  init = map => {
    this.map = map;
  };
  //请求场所边界
  getPolyline = placeId => {
    Service.place.placesExt({ id: placeId }).then(res => {
      let polyline = "";
      if (res.data && res.data.polyline) {
        polyline = JSON.stringify(res.data.polyline.split(";").map(v => v.split(",").map(x => x * 1)));
      }
      let polylineData = {
        polyline,
        id: res.data.id || "",
        areaName: res.data.areaName || ""
      };
      this.setState(
        {
          polylineData
        },
        () => this.map && this.map.createPolyline()
      );
    });
  };

  // 修改设备关联场所 //此处比较长度是因为checkbox 可取消单选的用法问题，后面再优化
  handlePlaceChange = (id, checked) => {
    let { bindId, cancelBind } = this.state;
    if (id === bindId) {
      cancelBind = checked ? false : true;
    }
    const selectPlaceIds = checked ? id : null;
    this.setState(
      {
        selectPlaceId: selectPlaceIds,
        cancelBind
      },
      () => (selectPlaceIds ? this.getPolyline(selectPlaceIds) : this.map.clearPolyline())
    );
  };

  clickMarker = deviceInfo => {
    const { place, point } = this.props;
    const { center, infoWindowVisible } = this.state;

    if (!center) {
      this.setState({
        center: [point.longitude, point.latitude]
      });
    }
    if (deviceInfo.placeId) {
      let exitPlaceInfo = place.getPlaceInfoById(deviceInfo.placeId) || {};
      this.setState({
        infoWindowVisible: !infoWindowVisible,
        exitPlaces: [exitPlaceInfo],
        selectPlaceId: exitPlaceInfo.placeId
      });
    }
  };

  render() {
    const { className = "", point, onOk, showPlaceModal = false, showSearch, ...props } = this.props;

    const { infoWindowVisible, center, disabled, polylineData, selectPlaceId } = this.state;
    return (
      <ModalComponent
        className={`device-point-modal-wrapper ${className}`}
        okButtonProps={{
          disabled
        }}
        width="50%"
        height="70%"
        {...props}
        onCancel={this.handleCancel}
        onOk={this.handleSubmit}
        destroyOnClose={true}
      >
        <SetPointMap
          mapRef={this.init}
          selectPlaceId={selectPlaceId}
          onChangePoint={this.handleChangePoint}
          point={point}
          polylineData={polylineData}
          showSearch
          markerClick={this.clickMarker}
          // clickAble={!infoWindowVisible}
        >
          <InfoWindow
            notMove={true}
            visible={infoWindowVisible && this.state.places.length > 0}
            center={center}
            content={<Info places={this.state.places} selectPlaceId={selectPlaceId} handlePlaceChange={this.handlePlaceChange}/>}
          />
        </SetPointMap>
      </ModalComponent>
    );
  }
}

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
  }
  componentDidMount() {
    this.domRef.current.addEventListener("mousewheel", this.onWheel, { passive: false });
  }
  componentWillUnmount() {
    this.domRef.current.removeEventListener("mousewheel", this.onWheel, { passive: false });
    this.domRef = null
  }
  onWheel = e => {
    Utils.stopPropagation(e);
  };
  render() {
    const { selectPlaceId, places, handlePlaceChange } = this.props;
    return (
      <div className="points-new-exist-place" ref={this.domRef}>
        <div className="new-place">
          {BSConfig.microService.place && !!places.length && (
            <>
              <div> 场所列表: </div>
              {places.map(items => {
                let id = items.placeIds[items.placeIds.length - 1];
                let checked = selectPlaceId === id;
                return (
                  <Checkbox checked={checked} onClick={() => handlePlaceChange(id, !checked)}>
                    {items.name}
                  </Checkbox>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ModalSetPointMap;
